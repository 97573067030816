<template>
    <div class="container-fluid mb-80"  @click="close">
        <div class="row">
            <div class="col-md-12 ">
                <h4 class="title-in-h">{{$t('sidebar.configurations')}}</h4>
                <breadcrumb >
                    <breadcrumb-item  v-for="breadcrumb in breadcrumbs" v-if="breadcrumb.type!=='active'">
                        <router-link :to="{path:breadcrumb.path}" class="color-blue-var">{{breadcrumb.name}}</router-link>
                    </breadcrumb-item>
                    <breadcrumb-item active v-else>
                        {{breadcrumb.name}}
                    </breadcrumb-item>
                </breadcrumb>
            </div>
        </div>



        <div class="row">
            <vue-topprogress ref="topProgress"></vue-topprogress>

            <div class="col-12">
                <card>
                    <div>
                        <div class="col-sm-12">
                            <div class="box-info-configurations">


                                <div class="box-item">
                                    <div class="row">
                                        <div class="col-sm-10">
                                            <div class="row">
                                                <div class="col-4">
                                                    <label class="title-config">Url booking:</label>
                                                </div>
                                                <div class="col-8 mar-top">
                                                    <fg-input type="text"
                                                              v-model="configuration.booking_url"
                                                              @input="formsLogs('url_booking')"
                                                              class="input-custom ipt-middle"

                                                    ></fg-input>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-2">
                                            <button 
                                              v-if="writting_permission" :disabled="!forms_log.url_booking"
                                              type="button" class="btn btn-inactive btn-fill btn-wd" :class="forms_log.url_booking?'btn-success':''" @click="checkForm('booking_url')">
                                                {{$t('form.update')}}
                                            </button>
                                        </div>
                                    </div>
                                </div>


                                <div class="box-item">
                                    <div class="row">
                                        <div class="col-sm-10">
                                            <div class="row">
                                                <div class="col-4">
                                                    <label class="title-config">{{ this.$t('property.config.type_change')}}</label>
                                                </div>
                                                <div class="col-8 mar-top">
                                                    <i class="fa fa-usd mr-2" aria-hidden="true"></i>

                                                    <fg-input type="text"
                                                              v-model="configuration.type_money"
                                                              class="input-custom ipt-small"
                                                              :class="error.money?'inp-error':''"
                                                              @input="formsLogs('currency')"

                                                    ></fg-input>

                                                    <span class="ml-3">MXN</span>
                                                    <span class="lbl-error ml-2" v-show="error.money">El tipo de cambio debe ser numérico</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-2">
                                            <button 
                                            v-if="writting_permission"  :disabled="!forms_log.currency"
                                            type="button" class="btn  btn-inactive btn-fill btn-wd" :class="forms_log.currency?'btn-success':''" @click="checkForm('currency')">
                                                {{$t('form.update')}}
                                            </button>
                                        </div>
                                    </div>
                                </div>


                                <div class="box-item">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="row">

                                                <div class="col-12 mar-top">
                                                    <span class="mr-2 title-config">Moneda base:</span>
                                                    <span>{{configuration.currency}}</span>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="box-item">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="row">
                                                <div class="col-12">
                                                    <label class="title-config">Las tarifas cargadas son {{configuration.included_taxes?'con':'sin'}} impuestos, por lo que los porcentajes agregados son:</label>
                                                </div>
                                                <div class="col-12 mar-top">
                                                    <span class="mr-2">Impuesto al valor agregado (IVA):</span>
                                                    <span>{{configuration.iva}} %</span>

                                                </div>
                                                <div class="col-12 mar-top">
                                                    <span class="mr-2">Impuesto sobre hospedaje (ISH):</span>
                                                    <span>{{configuration.ish}} %</span>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="box-item">
                                    <div class="row">
                                        <div class="col-sm-10">
                                            <label class="title-config">{{ this.$t('property.config.plan_food')}}</label>
                                            <div class="row"  v-for="item in configuration.plans_food">
                                                <div class="col-sm-5">
                                                    <label class="ul-item" :class="item.status?'checked':''">{{item.name}}</label>
                                                </div>
                                                <div class="col-sm-7">
                                                     <span class="fa fa-check item-checked" :class="item.status?'checked':''" @click="item.status = changeStatus(item.status, 'plans_food')" v-if="item.hasRateplans.length===0 || !item.status"></span>

                                                    <a v-tooltip.top-center="formatText(item.hasRateplans)" v-if="item.hasRateplans.length  &&  item.status">
                                                        <span class="fa fa-lock item-checked checked cursor-undrop"   ></span>
                                                    </a>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-2 d-flex">
                                            <button 
                                            v-if="writting_permission"   :disabled="!forms_log.plans_food"
                                            type="button" class="btn btn-inactive btn-fill btn-wd" :class="forms_log.plans_food?'btn-success':''" @click="checkForm('food_plan')">
                                                {{$t('form.update')}}
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div class="box-item">
                                    <div class="row">
                                        <div class="col-sm-10">
                                            <label class="title-config">{{ this.$t('property.config.payments')}}</label>
                                            <div class="row"  v-for="(item, key_idx) in info_page.payments">
                                                <div class="col-sm-5">
                                                    <label class="ul-item" :class="item.status?'checked':''">{{item.name}}</label>
                                                </div>
                                                <div class="col-sm-7">
                                                   <!-- <span class="fa fa-check item-checked" :class="item.status?'checked':''" @click="item.status = !item.status"></span>-->
                                                    <span class="fa fa-check item-checked" :class="item.status?'checked':''" @click="checkActivatePayment(item.code, item.status, key_idx)"></span>
                                                </div>
                                                <!--<div class="col-12">

                                                    <label class="text-alert" v-show="item.text!==''">{{item.text}}</label>
                                                </div>-->

                                                <div class="col-sm-5">

                                                    <div class="itm-payment" v-show="item.status" v-if="item.options.length" v-for="(payment, idx) in configuration.payments">
                                                       <fg-input   v-model="payment.value">
                                                            <el-select class="select-default" size="large"   v-model="payment.value"  @change="checkDifference()">
                                                                <el-option v-for="(option) in item.options" class="select-default" :value="option.value" v-show="option.status"
                                                                           :label="option.name">
                                                                </el-option>
                                                            </el-select>
                                                        </fg-input>

                                                        <a class="itm-ctrl" >
                                                            <i class="fa fa-plus" @click="addPayment()" v-tooltip.bottom="'Agregar tarjeta de credito'"  v-show="item.options.length > configuration.payments.length && idx === 0"></i><!---->
                                                            <i class="fa fa-minus"  @click="deletePayment(idx)" v-show="idx === 0 &&  item.options.length === configuration.payments.length"></i>
                                                            <i class="fa fa-minus"  @click="deletePayment(idx)" v-show="idx!== 0"></i>
                                                        </a>
                                                    </div>

                                                </div>
                                               <!--<div class="item-payment" v-for="(paramet, idx) in item.params" v-show="item.status">
                                                   <div class="row pl-5">
                                                       <div class="col-2 pr-0">
                                                           <label class="ul-item pl-0">
                                                               {{translateKeys(idx)}}:
                                                           </label>
                                                       </div>
                                                       <div class="col-10 ">
                                                           <input type="text" v-model="item.params[idx]" class="ipt-border" :class="error[idx]?'inp-error':''">
                                                           <span class="lbl-error" v-show="error[idx]">Este campo es requerido</span>
                                                       </div>
                                                   </div>
                                               </div>-->
                                            </div>
                                        </div>
                                        <div class="col-sm-2 d-flex">
                                            <button
                                              v-if="writting_permission" :disabled="!forms_log.payments"
                                              type="button" class="btn btn-inactive btn-fill btn-wd" :class="forms_log.payments?'btn-success':''" @click="checkForm('payments')">
                                                {{$t('form.update')}}
                                            </button>
                                        </div>
                                    </div>
                                </div>


                                <div class="box-item">
                                    <div class="row">
                                        <div class="col-sm-10">
                                            <label class="title-config">{{ this.$t('property.config.hotel_types')}}</label>

                                            <div class="row">
                                                <div class="col-sm-5">
                                                    <label class="ul-item" :class="configuration.hotels.adults?'checked':''">{{this.$t('property.config.only_adults')}}</label>
                                                </div>
                                                <div class="col-sm-6">
                                                    <span class="fa fa-check item-checked" :class="configuration.hotels.adults?'checked':''" @click="changeType()"></span>
                                                </div>
                                                <div class="col-12">
                                                    <label class="ul-item checked mt-2 mb-3" v-show="!configuration.hotels.adults">{{this.$t('property.config.age_config')}}</label>
                                                </div>

                                            </div>

                                            <div class="row box-ages"  v-for="(item, itm) in configuration.hotels.kids" v-if="!configuration.hotels.adults" v-show="!(item._id === 2 && !configuration.plans_food[2].status)">
                                                <div class="col-sm-6">
                                                    <div class="row">
                                                        <div class="col-4">
                                                            <label class="ul-item" :class="item.status?'checked':''">{{item.name}}</label>
                                                        </div>
                                                        <div class="col-6">
                                                            <label class="select-label"> {{item.from}}
                                                            </label>
                                                            <span class="mr-15px">a</span>
                                                            <fg-input v-model="item.to"  >
                                                                <el-select class="select-default" size="large"  v-model="item.to" @change="changeAge(item.to, itm)">
                                                                    <el-option v-for="x in configuration.hotels.max_age" class="select-default" :value="x"
                                                                               :label="x" v-if="x>item.from">
                                                                    </el-option>
                                                                </el-select>
                                                            </fg-input>

                                                        </div>
                                                        <div class="col-2">
                                                            <span class="fa fa-check item-checked" :class="item.status?'checked':''" @click="changeAgeStatus(item)"></span>
                                                        </div>
                                                    </div>

                                                </div>



                                            </div>
                                            <div class="row">
                                                <span class="lbl-error ml-4" v-show="error.type_hotel.status">{{error.type_hotel.text}}</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-2 d-flex">
                                            <button 
                                              v-if="writting_permission"  :disabled="!forms_log.type_hotel"
                                              type="buton" class="btn  btn-inactive btn-fill btn-wd" :class="forms_log.type_hotel?'btn-success':''" @click="checkForm('hotel_type')">
                                                {{$t('form.update')}}
                                            </button>
                                        </div>
                                    </div>
                                </div>





                                <div class="box-item">
                                    <div class="row">
                                        <div class="col-sm-10">
                                            <div class="row">
                                                <div class="col-12">
                                                    <label class="title-config">Horario de llegada y salida del huésped al hotel</label>
                                                </div>
                                                <div class="col-12 mar-top  mb-2">
                                                    <span class="mr-2 lbl-70">Llegada</span>

                                                    <div class="input-custom ipt-small">

                                                        <el-time-select
                                                                placeholder="Start time"
                                                                v-model="configuration.time_arrive"
                                                                @change="forms_log.times = true"
                                                                :picker-options="{
                                                        start: '08:30',
                                                        step: '00:15',
                                                        end: '18:30'    }"
                                                        >
                                                        </el-time-select>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <span class="mr-2 lbl-70">Salida</span>
                                                    <div class="input-custom ipt-small">
                                                        <el-time-select
                                                                placeholder="End time"
                                                                v-model="configuration.time_departure"
                                                                @change="forms_log.times = true"
                                                                :picker-options="{
                                                                start: '08:30',
                                                                step: '00:15',
                                                                end: '18:30',
                                                                }"
                                                                class="input-custom ipt-middle">
                                                        </el-time-select>
                                                    </div>
                                                    <span class="lbl-error complete-line" v-show="error.gtm">Este campo es requerido</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-2 d-flex">
                                            <button
                                                    v-if="writting_permission"   :disabled="!forms_log.times"
                                                    type="buton" class="btn  btn-inactive btn-fill btn-wd" :class="forms_log.times?'btn-success':''" @click="checkForm('schedules')">
                                                {{$t('form.update')}}
                                            </button>
                                        </div>
                                    </div>
                                </div>





                                <div class="box-item show box-colors" ref="colorpicker" >
                                    <div class="row">
                                        <div class="col-sm-10">
                                            <label class="title-config mb-3">{{ this.$t('property.config.custom_skin')}}</label>
                                            <div class="row"  v-for="(item, idx) in configuration.page_colors">
                                                <div class="col-sm-4" @click="close">
                                                    <label class="ul-item checked">{{item.text}}</label>
                                                </div>
                                                <div class="col-sm-3" >
                                                    <div class="input-group color-picker-component bx-color mb-2"  :class="error.color[idx]?'inp-error':''" @click.stop>
                                                        <input type="text" v-model="item.value" class=" inp-color"
                                                               @focus="openPicker(idx)"
                                                               @change="updateFromInput"
                                                               @input="item.value = item.value.toUpperCase()"

                                                        >
                                                        <span class="current-color"
                                                              :style="'background-color: ' + item.value"
                                                              @click="openPicker(idx)"

                                                        ></span>

                                                        <span class="picker ">
                                                            <chrome-picker  v-model="item.value" v-if="item.status"
                                                                           @input="updateFromPicker(item.value, idx)"  ></chrome-picker>
                                                        </span>
                                                    </div>
                                                </div>

                                                <div class="col-sm-4" >
                                                    <label class="lbl-error" v-show="error.color[idx]">Color invalido</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-2 d-flex">
                                            <button 
                                              v-if="writting_permission"  :disabled="!forms_log.colors"
                                              type="button" class="btn  btn-inactive btn-fill btn-wd" :class="forms_log.colors?'btn-success':''" @click="checkForm('colors')">
                                                {{$t('form.update')}}
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div class="box-item">
                                    <div class="row">
                                        <div class="col-sm-10">
                                            <div class="row">
                                                <div class="col-12">
                                                    <label class="title-config">Seguimiento E-Commerce</label>
                                                </div>
                                                <div class="col-12 mar-top">
                                                    <span class="mr-2">ID  de contenedor Google Tag Manager:</span>
                                                    <span>{{configuration.gtm}}</span>
                                                    <!--<input type="text" v-model="" class="ipt-border inp-size" :class="error.gtm?'inp-error':''" placeholder="GTM-XXXXXXX">-->
                                                    <span class="lbl-error complete-line" v-show="error.gtm">Este campo es requerido</span>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="box-item">
                                    <div class="row">
                                        <div class="col-sm-10">
                                            <div class="row">
                                                <div class="col-12">
                                                    <label class="title-config">Listado de destinatarios:</label>
                                                </div>
                                                <div class="col-12">
                                                    <label class="ul-item checked mt-3 mb-1">Visibles:</label>
                                                </div>
                                                <div class="col-8 mar-top">
                                                    <el-tag
                                                            :key="tag"
                                                            v-for="tag in configuration.mail_cc"
                                                            closable
                                                            :disable-transitions="false"
                                                            @close="handleClose(tag, 'mail_cc')">
                                                        {{tag}}
                                                    </el-tag>
                                                    <el-input
                                                            class="input-new-tag"
                                                            v-if="inputVisible_cc"
                                                            v-model="inputValue_cc"
                                                            ref="saveTagInput"
                                                            size="small"
                                                            @keyup.enter.native="handleInputConfirm('mail_cc')"
                                                            @blur="handleInputConfirm('mail_cc')"
                                                    >
                                                    </el-input>
                                                    <el-button v-else class="button-new-tag cursor-pointer" size="small" @click="showInput('mail_cc')">+ Agregar correo electrónico</el-button>
                                                    <span class="lbl-error complete-line ml-3" v-show="error.mail_cc.length">{{error.mail_cc}}</span>
                                                </div>
                                                <div class="col-12">
                                                    <label class="ul-item checked  mt-3 mb-1">Ocultos:</label>
                                                </div>
                                                <div class="col-8 mar-top">
                                                    <el-tag
                                                            :key="tag"
                                                            v-for="tag in configuration.mail_bcc"
                                                            closable
                                                            :disable-transitions="false"
                                                            @close="handleClose(tag, 'mail_bcc')">
                                                        {{tag}}
                                                    </el-tag>
                                                    <el-input
                                                            class="input-new-tag"
                                                            v-if="inputVisible_bcc"
                                                            v-model="inputValue_bcc"
                                                            ref="saveTagInput"
                                                            size="small"
                                                            @keyup.enter.native="handleInputConfirm('mail_bcc')"
                                                            @blur="handleInputConfirm('mail_bcc')"
                                                    >
                                                    </el-input>
                                                    <el-button v-else class="button-new-tag cursor-pointer" size="small" @click="showInput('mail_bcc')">+ Agregar correo electrónico</el-button>

                                                    <span class="lbl-error complete-line ml-3" v-show="error.mail_bcc.length">{{error.mail_bcc}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-2">
                                            <button
                                                    v-if="writting_permission" :disabled="!forms_log.mail_bcc"
                                                    type="button" class="btn btn-inactive btn-fill btn-wd" :class="forms_log.mail_bcc?'btn-success':''" @click="checkForm('mail_bcc')">
                                                {{$t('form.update')}}
                                            </button>
                                        </div>
                                    </div>
                                </div>


                                <div class="box-item d-none">
                                    <div class="row">
                                        <div class="col-sm-10">
                                            <div class="row">
                                                <div class="col-12">
                                                    <label  class="title-config">Huso Horario</label>
                                                </div>

                                                <div class="col-12 mar-top">
                                                    <div class="row">
                                                        <div class="col-2">
                                                            <span class="mr-2 ln-1m">Huso actual:</span>
                                                        </div>
                                                        <div class="col-10">
                                                            <template>
                                                                <el-select class="select-default full-w" size="large"
                                                                           v-model="configuration.uso"
                                                                           filterable
                                                                           placeholder="Buscar huso horario"
                                                                           :loading="loading">
                                                                    <el-option
                                                                            v-for="item in options"
                                                                            :key="item.name"
                                                                            :label="item.currentTimeFormat"
                                                                            :value="item.name">
                                                                    </el-option>
                                                                </el-select>
                                                            </template>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-2 d-flex">
                                            <button
                                                    v-if="writting_permission"  :disabled="!forms_log.colors"
                                                    type="button" class="btn  btn-inactive btn-fill btn-wd" :class="forms_log.colors?'btn-success':''" @click="checkForm('colors')">
                                                {{$t('form.update')}}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </card>
            </div>
        </div>
    </div>

</template>
<script>
    import swal from 'sweetalert2'
    import {Breadcrumb, BreadcrumbItem, Switch as LSwitch} from 'src/components/index'
    //import users from 'src/pages/Dashboard/Tables/users'
    import PropertyService from '../../../js/services/PropertyService';
    import {Select, Option, Tag, TimeSelect, Loading, Button} from 'element-ui'
    import { Chrome } from 'vue-color'
    import EventBus from "../../../js/helpers/EventBus";
    import { vueTopprogress } from 'vue-top-progress'
    //import chrome from '../src/components/Chrome.vue'
    import ContractService from '../../../js/services/ContractService';
    const contractService = new ContractService();

    import LogFunctions from '../../../js/functions/LogFunctions';
    import LogService from '../../../js/services/LogService';
    const propertyService = new PropertyService();
    import { mapGetters } from "vuex";
    //import moment from 'moment';
    import moment from 'moment-timezone';
    import { getTimeZones, rawTimeZones, timeZonesNames } from "@vvo/tzdb";
    const logFunctions = new LogFunctions();
    const logService = new LogService();


    export default {
        components: {
            [TimeSelect.name]: TimeSelect,
            Breadcrumb,
            LSwitch,
            'chrome-picker': Chrome,
            [Option.name]: Option,
            [Select.name]: Select,
            BreadcrumbItem,
            [Tag.name]: Tag,
            [Button.name]: Button,
            vueTopprogress
        },
        computed: {
          ...mapGetters(["currentUser","selected_property","writting_permission"]),

        },
        data() {
            return {
                log_page:{
                    action: null,
                    section: null,
                    property_id: null,
                    data: [],
                    date: new Date(),
                    object_id: null,
                    user_id: null,
                },
                colors: '#194d33',
                fullscreenLoading: false,
                loading: false,
                options: [],
                breadcrumbs:[
                    {
                        name: this.$t('property.index.home'),
                        path: '/admin/properties',
                        type: '',
                    },
                    {
                        name: this.$t('property.index.property'),
                        path: '/admin/properties',
                        type: '',
                    },

                    {
                        name: this.$t('sidebar.configurations'),
                        path: '',
                        type: 'active',
                    }
                ],
                info_page:{
                    original_info:{},
                    section_name: 'property',
                    property_id: {},
                    tab_lang: 'es',
                    langs:[{
                        name: this.$t('locale_es'),
                        locale: 'es'
                    },{
                        name: this.$t('locale_en'),
                        locale: 'en'
                    },],
                    payments:[
                        /*{
                            _id: 1,
                            name: 'Verificación tarjeta de credito',
                            options: [
                                {
                                    name: 'VISA',
                                    value: 'visa',
                                    status: true
                                },
                                {
                                    name: 'Mastercad',
                                    value: 'mastercard',
                                    status: true
                                },
                                {
                                    name: 'American Express',
                                    value: 'american-express',
                                    status: true
                                }
                            ],
                            status: true
                        },*/
                        {
                            _id: 2,
                            name: 'Pasarela de pago (OPENPAY)',
                            code: 'card',
                            options: [],
                            text: 'Por favor ingresar el ID y las llaves de integración de OPENPAY',
                            params: {
                                tdc_key: '',
                                tdc_public: '',
                                tdc_secret: '',
                            },
                            length_id: 20,
                            length_key: 35,
                            status: true
                        },

                        {
                            _id: 3,
                            name: 'Paypal',
                            code: 'paypal',
                            options: [],
                            text: 'Por favor ingresar el ID y las llaves de integración de PAYPAL',
                            params: {
                                paypal_key: '',
                                paypal_secret: '',

                            },
                            length_id: 80,
                            status: false
                        },
                        {
                            _id: 4,
                            code: 'destine',
                            name: 'Pago en el hotel (verificación de tarjeta)',
                            options: [],
                            status: false
                        },
                        {
                            _id: 5,
                            code: 'validatecard',
                            name: 'Verificación tarjeta de crédito / débito',
                            options: [
                            ],
                            status: false
                        },


                        {
                            _id: 6,
                            code: 'firstdata',
                            name: 'Pasarela de pago (FIRSTDATA)',
                            options: [
                            ],
                            status: false
                        },


                        {
                            _id: 7,
                            code: 'stripe',
                            name: 'Pasarela de pago (STRIPE)',
                            params: {
                                stripe_cuenta_id: '',
                                stripe_secret: '',

                            },
                            length_id: 21,
                            length_key: 107,
                            options: [
                            ],
                            status: false
                        },
                        /*{
                            _id: 5,
                            name: 'Pago en el hotel (sin verificación de tarjeta)',
                            options: [],
                            status: false
                        }*/
                    ]

                },

                inputVisible_bcc: false,
                inputValue_bcc: '',
                inputVisible_cc: false,
                inputValue_cc: '',
                configuration:{
                    mail_bcc: [],
                    mail_cc: [],
                    uso: null,
                    property_id : null,
                    booking_url: '',
                    currency: '',
                    gtm: '',
                    included_taxes: false,
                    iva: '',
                    ish: '',
                    time_arrive: '',
                    time_departure: '',
                    type_money: '',
                    plans_food:[
                        {
                            value: 1,
                            name: 'Plan Europeo',
                            status: true,
                            hasRateplans: [],
                        },
                        {
                            value: 2,
                            name: 'Plan Europeo + Desayuno',
                            status: true,
                            hasRateplans: [],
                        },
                        {
                            value: 3,
                            name: 'Todo Incluido',
                            status: false,
                            hasRateplans: [],
                        }
                    ],
                    hotels: {
                        adults: false,
                        max_age: 17,
                        kids: [
                            {
                                _id: 0,
                                value: 0,
                                name: 'Infantes (años)',
                                from: 0,
                                to: 4,
                                status: false

                            },
                            {
                                _id: 1,
                                value: 1,
                                name: 'Niños (años)',
                                from: 5,
                                to: 9,
                                status: false
                            },
                            {
                                _id: 2,
                                value: 1,
                                name: 'Jrs (años)',
                                from: 10,
                                to: 14,
                                status: false
                            },
                        ],

                    },


                    payments:[
                        {
                            name: 'VISA',
                            value: 'visa'
                        },
                    ],
                    page_colors:[
                        {
                            _id: 1,
                            text: 'Color principal (botones)',
                            value: '#FFFFFF',
                            status: false
                        },
                        {
                            _id: 2,
                            text: 'Color secundario (textos)',
                            value: '#FFFFFF',
                            status: false
                        },
                        {
                            _id: 3,
                            text: 'Color de fondo',
                            value: '#FFFFFF',
                            status: false
                        },
                    ]

                },
                forms_log:{
                    url_booking:    false,
                    currency:       false,
                    plans_food:     false,
                    payments:       false,
                    type_hotel:     false,
                    times:          false,
                    colors:         false,
                    mail_bcc: false
                },
                error:{
                    color:[false, false, false],
                    money: false,
                    gtm: false,
                    paypal_key: false,
                    paypal_secret: false,
                    tdc_key: false,
                    tdc_public: false,
                    tdc_secret: false,
                    stripe_cuenta_id: false,
                    stripe_secret: false,
                    type_hotel: {
                        status: false,
                        text: ''
                    },
                    mail_cc: '',
                    mail_bcc: '',


                },
                property: {
                    _id: '',
                    status: false,
                },
                section: 'PROPERTY',




            }
        },
        created(){
            this.chargerItems(this.selected_property._id);
            this.$store.dispatch('CHANGE_SECTION',this.section);
        },
        mounted() {
            //this.$refs.topProgress.start();
            this.initLoading();
            this.changeProperty()
            /*this.list = getTimeZones().map(item => {
                //console.log('list');
                return { value: item.name, name: item.currentTimeFormat};
            });*/
        },
        methods: {
            changeProperty(){
                EventBus.$on('CHANGE_PROPERTY', (property_id) => {
                    if(this.$route.name==='configurationProperty'){
                        //console.log(this.$route.name, 'rooms')
                        //this.$refs.topProgress.start();
                        this.initLoading();
                        this.chargerItems(property_id);
                    }
                })
            },
            configLogs(property_id, section_name){
                this.log_page.section = logFunctions.findSection(section_name)._id
                this.log_page.property_id = property_id;
                this.log_page.user_id = this.currentUser._id;
                this.log_page.action = 1;
                this.log_page.date = moment().format('YYYY-MM-DD HH:mm');
            },
            async createLog(object_id, action, data = []){
                let paramsLog= this.log_page;
                paramsLog.object_id = object_id;
                //paramsLog.section = logFunctions.findSection(this.info_page.section_name)._id
                paramsLog.action = action;
                paramsLog.data = data;
                paramsLog.date = moment().format('YYYY-MM-DD HH:mm:ss');
                await logService.create(paramsLog);
            },
            chargerItems(property_id){
                this.info_page.property_id = property_id;

                this.configLogs(property_id, this.info_page.section_name);

                this.forms_log = {
                    url_booking:    false,
                        currency:       false,
                        plans_food:     false,
                        payments:       false,
                        type_hotel:     false,
                        times:          false,
                        colors:         false,
                };




                propertyService.getPropertyId(property_id).then(response => {
                    let data = response.data.data.property;
                    this.info_page.original_info = JSON.parse(JSON.stringify(data))
                    //console.log('data', (data.color_header)?true:false, data.age_infants);
                    this.property._id = data._id;



                    /***************** Seccion booking url *********************/
                    this.configuration.booking_url = data.booking_url;

                    /***************** Seccion tipo de cambio ******************/
                    this.configuration.type_money = data.currency_exchange;

                    /***************** Seccion plan de comida ******************/
                    this.configuration.plans_food[0].status = data.plan_ep;
                    this.configuration.plans_food[1].status = data.plan_epb;
                    this.configuration.plans_food[2].status = data.plan_ai;

                    /***************** Seccion plan de pagos ******************/
                    this.info_page.payments[0].status = data.pay_tdc;
                    this.info_page.payments[1].status = data.pay_paypal;
                    this.info_page.payments[2].status = data.pay_hotel;



                    if(data.pay_validate!==undefined){
                        this.info_page.payments[3].status = data.pay_validate;

                    }
                    if(data.pay_firstdata!==undefined){
                        this.info_page.payments[4].status = data.pay_firstdata;
                    }

                    console.log('data', data.pay_stripe);
                    if(data.pay_stripe!==undefined){
                        this.info_page.payments[5].status = data.pay_stripe;
                        this.info_page.payments[5].params.stripe_secret = data.stripe_secret;
                        this.info_page.payments[5].params.stripe_cuenta_id = data.stripe_cuenta_id;
                    }


                    this.configuration.included_taxes = data.included_taxes;
                    this.configuration.iva = data.iva;
                    this.configuration.ish = data.ish;
                    this.configuration.currency = data.currency;

                    if(data.mail_bcc!==undefined){
                        this.configuration.mail_bcc = data.mail_bcc;
                    }
                    if(data.mail_cc!==undefined){
                        this.configuration.mail_cc = data.mail_cc;
                    }


                    this.configuration.time_arrive = data.time_arrive;
                    this.configuration.time_departure = data.time_departure;



                    /*********** Seccion configuracion de colores *************/
                    this.info_page.payments[0].params.tdc_key = data.tdc_key;
                    this.info_page.payments[0].params.tdc_public = data.tdc_public;
                    this.info_page.payments[0].params.tdc_secret = data.tdc_secret;
                    this.info_page.payments[1].params.paypal_key = data.paypal_key;
                    this.info_page.payments[1].params.paypal_secret = data.paypal_secret;


                    /*********** Seccion configuracion de seo *************/
                    this.configuration.gtm = data.gtm_id;


                    /***************** Seccion tipo de hotel ******************/

                   //console.log('data', data);


                    let age_min = 0;

                    this.configuration.hotels.adults = data.only_adults;

                    if(data.age_infants!==null && (data.age_infants)){
                        this.configuration.hotels.kids[0].status = true;
                        this.configuration.hotels.kids[0].from = age_min;
                        this.configuration.hotels.kids[0].to = (data.age_infants)?data.age_infants:5;
                        age_min = data.age_infants+1;
                    }else{

                        //this.configuration.hotels.kids[0].status = false;
                        this.configuration.hotels.kids[0].from = '';
                        this.configuration.hotels.kids[0].to = '';

                    }
                    if(data.age_child!==null && (data.age_child)){
                        this.configuration.hotels.kids[1].status = true;
                        this.configuration.hotels.kids[1].from = age_min;
                        this.configuration.hotels.kids[1].to = (data.age_child)?data.age_child:9;
                        age_min = data.age_child+1;
                    }else{

                        this.configuration.hotels.kids[1].from = '';
                        this.configuration.hotels.kids[1].to = '';
                    }
                    if(data.age_jr !==null && (data.age_jr)){
                        this.configuration.hotels.kids[2].status = true;
                        this.configuration.hotels.kids[2].from = age_min;
                        this.configuration.hotels.kids[2].to = (data.age_jr)?data.age_jr:15;
                    }else{
                        this.configuration.hotels.kids[2].from = '';
                        this.configuration.hotels.kids[2].to = '';

                    }

                    /*data.age_jr = (data.age_jr)?data.age_jr:15;
                    data.age_infants = (data.age_infants)?data.age_infants:'';
                    data.age_child = (data.age_child)?data.age_child:9;

                    this.configuration.hotels.adults = data.only_adults;
                    this.configuration.hotels.kids[0].to = (data.age_infants)?data.age_infants:'';

                    this.configuration.hotels.kids[1].from = parseInt(data.age_infants + 1);
                    this.configuration.hotels.kids[1].to = data.age_child;


                    this.configuration.hotels.kids[2].from = parseInt(data.age_child + 1);
                    this.configuration.hotels.kids[2].to = data.age_jr;*/


                    /*********** Seccion configuracion de colores *************/
                    this.configuration.page_colors[0].value = (data.color_header)?data.color_header:'#FFFFFF';
                    this.configuration.page_colors[1].value = (data.color_second)?data.color_second:'#FFFFFF';
                    this.configuration.page_colors[2].value = (data.color_main)?data.color_main:'#FFFFFF';

                        contractService.getContracts('true', property_id).then(async response => {
                           //console.log('rateplans', response.data.data.rateplans);
                            let rateplans = response.data.data.rateplans;
                            this.configuration.plans_food[0].hasRateplans = rateplans.filter(item=>item.meal_plan === 1);
                            this.configuration.plans_food[1].hasRateplans = rateplans.filter(item=>item.meal_plan === 2);
                            this.configuration.plans_food[2].hasRateplans = rateplans.filter(item=>item.meal_plan === 3);
                            this.closeLoading();
                        });


                        this.getTimesZones();





                    //this.$refs.topProgress.done()


                });
            },
            handleEdit(id, section) {
                this.$router.push({
                    name: section,
                    params: {
                        id: id
                    }
                })
            },

            openPicker(idx){
               //console.log('open picker');
                //document.addEventListener("click", this.close);
                this.configuration.page_colors.forEach(item => {
                    item.status = false;
                })
                this.configuration.page_colors[idx].status = true;
                //status = true;
            },


            updateFromPicker(item, idx) {
               //console.log('changed by picker', item.hex, idx);
                this.configuration.page_colors[idx].value = item.hex;
                this.forms_log.colors = true;
                this.formsLogs('colors')
                //return item.hex;
                //
            },
            close(e) {
                //document.removeEventListener("click", this.close);

                this.configuration.page_colors.forEach(item => {
                    item.status = false;
                })
                //console.log(e);
                //return item.hex;
                //
            },
            handleClose(tag, typeIs) {

                if(typeIs === 'mail_bcc'){
                    this.configuration.mail_bcc.splice(this.configuration.mail_bcc.indexOf(tag), 1);
                    this.formsLogs('mail_bcc')
                }
                if(typeIs === 'mail_cc'){
                    this.configuration.mail_cc.splice(this.configuration.mail_cc.indexOf(tag), 1);
                    this.formsLogs('mail_bcc')
                }
            },

            showInput(typeIs) {


                if(typeIs === 'mail_bcc'){

                    this.inputVisible_bcc = true;
                }
                if(typeIs === 'mail_cc'){

                    this.inputVisible_cc = true;
                }

                this.$nextTick(_ => {
                    this.$refs.saveTagInput.$refs.input.focus();
                });
            },

            handleInputConfirm(typeIs) {

                if(typeIs === 'mail_bcc'){
                    let inputValue = this.inputValue_bcc;
                    if (inputValue) {
                        this.error.mail_bcc = '';
                        if(this.validateEmail(inputValue)){
                            this.configuration.mail_bcc.push(inputValue);
                        }else{
                            this.error.mail_bcc = 'Correo electrónico inválido';
                        }
                    }
                    this.inputVisible_bcc = false;
                    this.inputValue_bcc = '';
                    this.formsLogs('mail_bcc')
                }
                if(typeIs === 'mail_cc'){
                    let inputValue = this.inputValue_cc;
                    if (inputValue) {
                        this.error.mail_cc = '';
                        if(this.validateEmail(inputValue)){
                            this.configuration.mail_cc.push(inputValue);
                        }else{
                            this.error.mail_cc = 'Correo electrónico inválido';
                        }
                    }
                    this.inputVisible_cc = false;
                    this.inputValue_cc = '';
                    this.formsLogs('mail_bcc')
                }

            },
            validateEmail(email) {
                const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },
            validateURLBooking(url_validate){
                if(url_validate.endsWith("/")){
                   url_validate =  url_validate.slice(0, -1);
                }
                return url_validate
            },


            updateFromInput(event) {
               //console.log('changed by input');
                this.forms_log.colors = true;
                this.formsLogs('colors')
            },
            onChange(){
               //console.log('cambio el color')

            },
            updateStatus(id, status, field){
                /*status = (status)?'false':'true';
                //console.log('cambiandop status', id, status);
                contractService.updateStatus(id, status).then(response => {
                    console.log('iresponse',  response);
                });*/

                /*setTimeout(() => {
                    let indexToDelete = this.tableData.findIndex((tableRow) => tableRow._id === id)
                    if (indexToDelete >= 0) {
                        this.tableData.splice(indexToDelete, 1)
                    }

                    if(status===true){
                        indexToDelete = this.items_i.findIndex((tableRow) => tableRow._id === id);
                        if (indexToDelete >= 0) {
                            this.items_i.splice(indexToDelete, 1)
                        }
                        status = 'true';

                    }else{
                        indexToDelete = this.items.findIndex((tableRow) => tableRow._id === id);
                        if (indexToDelete >= 0) {
                            this.items.splice(indexToDelete, 1)
                        }
                        status = 'false';
                    }

                    contractService.updateStatus(id, status).then(response => {

                        let contract = response.data.data.rateplan;
                        //console.log(response.data, 'respuesta')
                        if(contract.status===true){
                            this.items.push(contract)
                        }else{
                            this.items_i.push(contract)
                        }

                        this.info_page.actives =  this.items.length;
                        this.info_page.inactives =  this.items_i.length;
                    });
                }, 800);*/


            },


            /******************* Post de formularios **************************************/

            remoteMethod(query) {
                if (query !== '') {
                    this.loading = true;
                    setTimeout(() => {
                        this.loading = false;
                        this.options = this.list.filter(item => {
                            return item.name.toLowerCase()
                                .indexOf(query.toLowerCase()) > -1;
                        });
                    }, 200);
                } else {
                    this.options = [];
                }
            },

            checkForm(option){
                let datas = {};
                let errors = 0;
                console.log('check form')

                switch (option) {

                    case 'seo':

                        this.error.gtm = false;
                        datas  = {
                            gtm_id: this.configuration.gtm
                        };
                        if(this.configuration.gtm.trim().length === 0){
                            errors++;
                            this.error.gtm = true;
                        }

                        break;

                    case 'booking_url':
                        datas  = {
                            booking_url: this.validateURLBooking(this.configuration.booking_url)
                        };
                        break;


                    case 'mail_bcc':
                        //console.log('mail_cc', this.configuration.mail_cc);
                        datas  = {
                            mail_bcc: this.configuration.mail_bcc,
                            mail_cc: this.configuration.mail_cc
                        };

                        if(this.configuration.mail_cc.length === 0){
                            errors++;
                            this.error.mail_cc = 'Es requerido agregar un correo electrónico';
                        }



                        break;


                    case 'currency':
                        this.error.money = false;
                        datas  = {
                            currency_exchange: this.configuration.type_money
                        };
                        if(this.validNumber(this.configuration.type_money)){
                            errors++;
                            this.error.money = true;
                        }

                        break;
                    case 'food_plan':
                        datas  = {
                            plan_ep: this.configuration.plans_food[0].status,
                            plan_epb: this.configuration.plans_food[1].status,
                            plan_ai: this.configuration.plans_food[2].status,
                        };
                        if(!datas.plan_ai){
                            datas.age_jr = null;
                        }
                        break;
                    case 'payments':
                        datas  = {
                            pay_tdc: this.info_page.payments[0].status,
                            pay_paypal: this.info_page.payments[1].status,
                            pay_hotel: this.info_page.payments[2].status,
                            pay_validate: this.info_page.payments[3].status,
                            pay_firstdata: this.info_page.payments[4].status,
                            pay_stripe: this.info_page.payments[5].status,
                        };



                            this.info_page.payments.forEach(payment=>{
                                if(payment.status){
                                    for (let prop in payment.params) {
                                       console.log('map test', payment);



                                        this.error[prop] = false;
                                        if(payment.params[prop].trim().length === 0){
                                            this.error[prop] = true;
                                            errors++;
                                        }else{
                                            datas[prop] = payment.params[prop];
                                        }

                                    }
                                }
                            });
                        break;
                    case 'hotel_type':
                        this.error.type_hotel.status = false;
                        if(!this.configuration.hotels.adults){
                            //console.log('hotel_type ');
                            let statSelectedAge = 0;
                            let minSelectedAge = 0;
                            this.configuration.hotels.kids.forEach((item, idx) =>{
                                //console.log('hotel_type ', item);
                                if(item.status){
                                    statSelectedAge++;
                                    if(item.to==='' || item.to===null){
                                        minSelectedAge++;
                                    }
                                }
                            });

                            if(minSelectedAge>0  || statSelectedAge===0){
                                errors++;
                                this.error.type_hotel.status = true;

                                this.error.type_hotel.text = 'Es requerido especificar el máximo de edad';
                                if(statSelectedAge===0){
                                    this.error.type_hotel.text = 'Es requerido activar un bloque de edad';
                                }
                            }
                        }

                        if(!this.configuration.hotels.kids[2].status){
                            this.configuration.hotels.kids[2].to = null;
                            this.configuration.hotels.kids[2].from= null;
                        }
                        if(!this.configuration.hotels.kids[1].status){
                            this.configuration.hotels.kids[1].to = null;
                            this.configuration.hotels.kids[1].from= null;
                        }
                        if(!this.configuration.hotels.kids[0].status){
                            this.configuration.hotels.kids[0].to = null;
                            this.configuration.hotels.kids[0].from= null;
                        }
                        datas  = {
                            only_adults: this.configuration.hotels.adults,
                            age_infants: this.configuration.hotels.kids[0].to,
                            age_child: this.configuration.hotels.kids[1].to,
                            age_jr: this.configuration.hotels.kids[2].to,
                        };
                        if(this.configuration.plans_food[2].status===false){
                            datas.age_jr = null;
                        }


                        break;
                    case 'schedules':
                        datas  = {
                            time_arrive: this.configuration.time_arrive,
                            time_departure: this.configuration.time_departure
                        };
                        break;
                    case 'colors':
                        this.error.color.forEach((item, idx) =>{
                            this.$set( this.error.color, idx, false);
                        });
                        datas  = {
                            color_main: this.configuration.page_colors[2].value,
                            color_second: this.configuration.page_colors[1].value,
                            color_header: this.configuration.page_colors[0].value,
                        };

                        this.configuration.page_colors.forEach((item, idx) =>{

                            if(!this.validColor(item.value)){
                                errors++;
                                this.$set( this.error.color, idx, true);
                            }

                        })



                        break;
                }


                if(errors===0){
                    //console.log('simulando envio de: ', datas)
                    this.sendForm(datas);
                }



            },
            getTimesZones(){
                //console.log(moment.tz.names());

                /*const allZones = ;
                console.log('allZones', allZones);
                this.info_page.timeZones = allZones;*/
                let timesAll = getTimeZones();
                //console.log('timesAll', timesAll);
                timesAll.sort((a,b) => this.compareObjects(a, b, 'currentTimeFormat', 'ascending'));

                //console.log('timesAll II', timesAll);

                this.options = timesAll;

            },

            compareObjects(object1, object2, key, orderIs = 'ascending') {
                const obj1 = object1[key].toUpperCase()
                const obj2 = object2[key].toUpperCase()
                let comparison = 0;

                if (obj1 < obj2) {
                    comparison = -1
                }
                if (obj1 > obj2) {
                    comparison =  1
                }

                if(orderIs==='descending'){
                    return comparison * -1
                }

                return comparison

            },

            sendForm(data){
                this.initLoading('Guardando');
                propertyService.updateConfigProperty(this.info_page.property_id, data)
                    .then(async (response) => {
                        let propertyInfo = response.data.data.property;
                        let changes = await logFunctions.cheackProp(this.info_page.original_info, propertyInfo, this.info_page.section_name)
                        if(changes.length){
                            await this.createLog(propertyInfo._id, 1, changes);
                        }
                        this.$store.dispatch('UPDATE_PROPERTY', response.data.data.property);


                    })
                    .catch(error => {
                       //console.log(error);
                    })
                    .finally(() => {

                        this.closeLoading();
                        this.showSwal(this.$t('property.config.msg'));
                    });
            },
            showSwal (msg) {

                swal({
                    title: this.$t('property.new.modal'),
                    text: msg,
                    buttonsStyling: false,
                    confirmButtonClass: 'btn btn-success btn-fill',
                    type: 'success'
                }).then(()=> {

                    this.chargerItems(this.selected_property._id);


                   /* this.$router.push({
                        name: 'Contracts'
                    });*/
                });
            },

            checkActivatePayment(type, status, key_idx){
               //console.log('test beta', type, status, key_idx)
                let configuration = null;

                this.forms_log.payments = true;
                this.formsLogs('payments')
                switch (type) {
                    case 'paypal':
                        configuration = this.info_page.payments[1];

                        if(configuration.params.paypal_key.length===configuration.length_id
                            && configuration.params.paypal_secret.length === configuration.length_id){
                            this.info_page.payments[key_idx].status = !status;
                        }else{
                            this.alertError(type)
                        }

                        break;
                    case 'card':
                        configuration = this.info_page.payments[0];


                        //console.log('test beta', configuration.params.tdc_key.length, configuration.params.tdc_public.length, configuration.params.tdc_secret.length);

                        if(configuration.params.tdc_key.length===configuration.length_id
                            && configuration.params.tdc_public.length === configuration.length_key
                            && configuration.params.tdc_secret.length === configuration.length_key){
                            this.info_page.payments[key_idx].status = !status;
                        }else{
                            this.alertError(type)
                        }

                        break;
                    case 'destine':
                    case 'validatecard':

                        this.info_page.payments[key_idx].status = !status;
                        break;
                    case 'firstdata':
                        this.info_page.payments[key_idx].status = !status;
                        break;
                    case 'stripe':
                        /*
                        *
                        this.info_page.payments[5].params.stripe_secret = data.stripe_secret;
                        this.info_page.payments[5].params.stripe_cuenta_id = data.stripe_cuenta_id;
                        * */
                        configuration = this.info_page.payments[5];
                        if(configuration.params.stripe_cuenta_id.length===configuration.length_id
                            && configuration.params.stripe_secret.length === configuration.length_key){
                            this.info_page.payments[key_idx].status = !status;
                        }else{
                            this.alertError(type)
                        }
                        break;


                }



            },

            alertError(type){
                //console.log('alertError', row, contracts)
                let htmlList= '<ul class="ml-3" style="text-align: left">'
                let option = '';
                let payment_id = null;



                if(type==='paypal'){
                    option = 'PayPal';
                    payment_id = 1;
                }
                if(type==='card'){
                    option = 'Openpay'
                    payment_id = 0;
                }
                if(type==='stripe'){
                    option = 'Stripe'
                    payment_id = 5;
                }


                let payments = this.info_page.payments[payment_id].params;
                for (let prop in payments) {
                    htmlList +='<li>'+this.translateKeys(prop)+'</li>';
                }




                    /*contracts.forEach(contract=>{

                    })*/

                htmlList+= '</ul>'


                let htmlResponse = '<p>Por favor de contactar al equipo de soporte, para  habilitar '+
                    option+' requerimos la siguiente información:</p>';

                htmlResponse += htmlList;

                swal({
                    title: 'Acción cancelada',
                    type: 'warning',
                    html:htmlResponse,
                    confirmButtonText: 'Aceptar',
                    buttonsStyling: false,
                    confirmButtonClass: 'btn btn-success btn-fill'
                })

            },


            /*******************************Validaciones*******************************/
            validNumber(item){
                //console.log('validando cantidad1', isNaN(item))
                return isNaN(item);

            },
            validColor(item){
               //console.log('checando coolores');
               var pattern = new RegExp("^#([a-fA-F0-9]){3}$|[a-fA-F0-9]{6}$");
               let resp = true;

               if(!pattern.test(item)) {
                   resp = false
                  //console.log('color invalido', item)
               }
               return resp;
           },

            /*******************************Funciones para seccion pagos*******************************/
            addPayment(){
                this.configuration.payments.push({
                    value:'',
                    name:'',

                })
                this.checkDifference();
            },
            deletePayment(n){
                this.configuration.payments.splice(n, 1)
                this.checkDifference();
            },
            checkDifference(){
                let  selected_options = this.configuration.payments;
                //console.log('this.payments', this.payments)
                let full_options = this.info_page.payments[0].options;
                full_options.forEach(item => {
                    item.status = true;
                });

                selected_options.forEach(item => {
                    //console.log('encontrados',full_rooms.findIndex(room => room._id === item.room_id))
                    let idx = full_options.findIndex(option => option.value === item.value);
                    if(idx>=0){
                        full_options[idx].status = false;
                    }
                })
            },
            findIndex(arr, value) {
                let obj = arr.find(o => o.value === value);
                return  arr.indexOf(obj);
            },
            changeAge(value, position){


               //console.log('blocksAvailables');
                let age_min = 0;

                if(this.configuration.hotels.kids[0].status){
                    this.configuration.hotels.kids[0].from = age_min;
                    age_min =  this.configuration.hotels.kids[0].to+1;
                }else{
                    this.configuration.hotels.kids[0].from = '';
                    this.configuration.hotels.kids[0].to = '';
                }

                if(this.configuration.hotels.kids[1].status){

                    this.configuration.hotels.kids[1].from = age_min;
                    if(this.configuration.hotels.kids[1].from>this.configuration.hotels.kids[1].to){
                        this.configuration.hotels.kids[1].to  = this.configuration.hotels.kids[1].from+1;
                    }
                    age_min =  this.configuration.hotels.kids[1].to+1;
                }else{
                    this.configuration.hotels.kids[1].from = '';
                    this.configuration.hotels.kids[1].to = '';
                }
                if(this.configuration.hotels.kids[2].status){

                    this.configuration.hotels.kids[2].from = age_min;
                    if(this.configuration.hotels.kids[2].from>this.configuration.hotels.kids[2].to){
                        this.configuration.hotels.kids[2].to  = this.configuration.hotels.kids[2].from+1;
                    }

                    //age_min =  this.configuration.hotels.kids[2].to+1;
                }else{
                    this.configuration.hotels.kids[2].from = '';
                    this.configuration.hotels.kids[2].to = '';
                }

                this.formsLogs('ages');
                this.forms_log.type_hotel = true;

                /*if(position!== 2){
                    this.configuration.hotels.kids[position+1].from = value+1;
                    if(this.configuration.hotels.kids[position+1].from >= this.configuration.hotels.kids[position+1].to){
                        this.configuration.hotels.kids[position+1].to = value+2;
                    }
                }*/
            },

            changeAgeStatus(item){
                item.status = !item.status;
                this.forms_log.type_hotel = true;
                this.formsLogs('ages');
                let blocksAvailables = [];
                this.configuration.hotels.kids.forEach((item)=>{
                    //console.log('item', item);
                    if(item.status){
                        blocksAvailables.push(item);
                    }
                });
                if(blocksAvailables.length){
                    let idx = this.configuration.hotels.kids.findIndex(option => option._id === blocksAvailables[0]._id);
                    if(idx>=0){
                        this.configuration.hotels.kids[idx].from = 0;
                    }

                    let val_min = 0;
                    blocksAvailables.forEach((item, index)=>{
                        //console.log('item', item);

                        if(item.status && blocksAvailables.length-1>index ){

                            if(item.to+1 !== blocksAvailables[index+1].from ){
                                let idx2 = this.configuration.hotels.kids.findIndex(option => option._id === item._id);
                                if(idx2>=0){
                                    if(blocksAvailables[index+1].from > 1){
                                        this.configuration.hotels.kids[idx2].to = blocksAvailables[index+1].from-1;
                                    }else{
                                        this.configuration.hotels.kids[idx2+1].from = blocksAvailables[index].to+1;
                                    }
                                }

                            }
                        }
                    });


                }

               //console.log('blocksAvailables', blocksAvailables);
            },

            translateKeys(value){
                if(value==='tdc_key')
                    value = 'ID';
                if(value==='tdc_public')
                    value = 'Llave Pública';
                if(value==='tdc_secret')
                    value = 'Llave Privada';
                if(value==='paypal_key')
                    value = 'Cliente ID';
                if(value==='paypal_secret')
                    value = 'Llave Secreta';

                if(value==='stripe_cuenta_id')
                    value = 'Cuenta ID';

                if(value==='stripe_secret')
                    value = 'Llave Secreta';


                return value;
            },

            changeType(){
                this.configuration.hotels.adults = !this.configuration.hotels.adults
                this.error.type_hotel.status = false;
                this.forms_log.type_hotel = true;
            },

            changeStatus(status, type){
                if(type === 'plans_food'){
                    console.log('test', status, type);
                    this.forms_log.plans_food = true;
                    this.formsLogs(type)
                }
                return !status;
            },
            formatText(rateplans){
                let stringReturn = '';
                if(rateplans.length>1){
                    stringReturn = 'Plan de alimento activo en los siguientes contratos: ';
                }else{
                    stringReturn = 'Plan de alimento activo en el siguiente contrato: ';
                }

                rateplans.forEach((item, idx)=>{
                    if(idx<1){
                        stringReturn += ' '+item.name;
                    }else{
                        stringReturn += ', '+item.name;
                    }

                })
                return stringReturn;
            },
            initLoading(text = 'Cargando...') {
                if(text==='Cargando...'){
                    this.$store.dispatch('CHANGES_ACTIVES', false);
                    this.records = [];
                }
                const loading = Loading.service({
                    lock: true,
                    text: text,
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.fullscreenLoading = loading;
            },
            closeLoading(){
                this.fullscreenLoading.close();
            },
            formsLogs(url){
                this.$store.dispatch('CHANGES_ACTIVES', true);
                if(url==='url_booking'){
                    this.forms_log.url_booking = true
                }
                if(url==='currency'){
                    this.forms_log.currency = true;
                }
                if(url==='mail_bcc'){
                    this.forms_log.mail_bcc = true;
                }

            }



            /**********************************************************************************************************/


        }
    }
</script>
<style lang="scss">
    .full-w{
        width: 100%;
    }
    .ln-1m{
        line-height: 35px;
    }
    .cursor-undrop{
        cursor: no-drop;
    }
    .lbl-error{
        color: #fb404b !important;
        text-transform: none !important;
        font-size: 12px;
        &.complete-line{
            width: 100%;
            display: inline-block;
        }
    }



    .inp-error{
        border-color: #fb404b !important;
    }
    .box-colors{
        .ul-item.checked{
            line-height: 30px;
        }
        .bx-color {
            width: auto;
            display: inline-block;
            border: 1px solid #E3E3E3;
            margin-bottom: 0.5rem;
            background: #ebebeb;
            border-radius: 5px;
            input{
                background: #fff;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                border: 0;
                width: 100px;
                position: relative;
                top: -1px;
                padding-left: 7px;
            }
        }
        .color-picker-component{
            .current-color {
                display: inline-block;
                width: 20px;
                height: 20px;
                background-color: #000;
                cursor: pointer;
                margin: auto 3px;
                top: 3px;
                position: relative;
                border-radius: 15px;
            }
            .ipt-border {
                width: 100px;
                border: 0;
                margin: 0;
                border-right: 2px solid;
                float: left;
                display: inline-block;
                height: 28px;
            }
        }

    }


    .itm-ctrl{
        height: 40px;
        display: inline-block;
        i{
            text-align: center;
            line-height: 40px;
            margin-left: 10px;
        }
    }



    .color-picker-component{

        .picker {
            position: absolute;
            left: 10px;
            top: 30px;
            z-index: 10;
            margin-bottom: 50px;
        }
    }
    .bx-color{
        width: auto;
        display: inline-block;
    }
    .mb-80{
        margin-bottom: 130px;
    }
    .lbl-70{
        display: inline-block;
        width: 70px;
    }


    .box-info-configurations{
        border: 0;
        padding: 40px 0;
        display: inline-block;

        .select-label{
            width: 28px;
            font-weight: 600;
            font-size: 16px;
            text-align: right;
            padding-right: 10px;
        }
        .mr-15px{
            margin-right: 15px;
        }
        .box-item {
            padding: 15px;
            border: 2px solid #eeeeee;
            overflow: hidden;
            float: left;
            margin: 0;
            width: 100%;
            margin-bottom: 15px;
            &.show{
                overflow: visible;
            }
            .text-alert{
                text-transform: none;
                margin-left: 30px;
                margin-bottom: 10px;
                color: goldenrod;
                font-size: 15px;
            }
            .itm-payment{
                width: 90%;
                padding-left: 5%;
                display: inline-block;
                .form-group{
                    width: 80%;
                    float: left;
                }
                .item-control{
                    width: 20%;
                    float: left;
                    line-height: 37px;
                    text-align: center;
                }
            }
            .box-ages{
                .form-group{
                    width: 42%;
                    display: inline-block;
                }
            }
        }
        .title-config{
            text-transform: none;
            display: inline-block;
            font-size: 20px;
            color: #000000;
            line-height: 37px;
            margin-bottom: 0;
        }
        .d-flex{
            display: flex;
        }
        .btn-success, .btn-inactive{
            min-width: 100%;
            vertical-align: middle;
            margin: auto;
            height: 37px;
            text-align: center;
        }
        .ipt-border{
            width: 100px;
            border: 2px solid;
            margin: 0 10px;


            &.double{
                width: 200px;

            }
            &.inp-size{
                padding: 0 7px;
                width: 300px;
            }
        }
        .mar-top{
            padding-top: 4px;
        }
        .ul-item{
            padding-left: 15px;
            text-transform: none;
            font-size: 15px;
            &.checked{
                color: #000000;
            }

        }
        .item-checked{
            color: #E1D8E9;
            &.checked{
                color: #1FBF64;
            }
        }
        .item-payment{
            width: 100%;
            display: inline-block;
            .lbl-error{
                margin-bottom: 5px;
                display: inline-block;
            }
            input{
                font-size: 11px;
                width: 100%;
                margin: 0 0 7px;
                padding: 0 7px;
                height: 25px;
                &.inp-error{
                    margin-bottom: 0;
                }
            }

        }
    }
    .input-custom{
        display: inline-block;
        &.ipt-small {
            width: 100px;
        }
        &.ipt-middle{
            width: 350px;
        }
    }
    .el-tag + .el-tag {
        margin-left: 10px;

    }
    .el-tag {
        background-color: #6b3e914d;
        border-color: #6b3e914d;
        color: #6B3E91;
        .el-tag__close:hover {
            background:  transparent;
            color: #6B3E91;
        }

    }
    .button-new-tag {
        margin-left: 10px;
        height: 32px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }
    .input-new-tag {
        input{
            width: 150px;
            padding-left: 5px;
            padding-right: 5px;

        }
        margin-left: 10px;
        vertical-align: top;
    }

</style>
