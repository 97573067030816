import BackendService from './BackendService';

export default class ContractService extends BackendService {

    constructor() {
        super();
    }





    getContracts(status, property_id) {
        //console.log('get rooms');

        return this.httpCall('rateplans',{
            params: {
                status: status,
                property_id: property_id
            },
        });
    }

    getRateplanIndex(params) {
        return this.httpCall('rateplans',{
            params: params,
        });
    }


    getContractsAll(property_id) {
        //console.log('get rooms');

        return this.httpCall('rateplans',{
            params: {
                property_id: property_id
            },
        });


        //return this.httpCall('property' + property_id + '/users');
    }
    getContractId(contract_id) {

        return this.httpCall('rateplan/'+contract_id);

        //return this.httpCall('property' + property_id + '/users');
    }

    createContract(contract) {

        return this.httpCall('rateplan',{
            method: 'POST',
            data: {
                name: contract.es.name,
                cod: contract.cod,
                meal_plan: contract.meal_plan,
                currency: contract.currency,
                cancellation_policy: contract.cancellation_policy,
                rooms: contract.rooms,
                status: contract.status,
                property_id:contract.property_id,
                is_public: contract.is_public,
                payment_options: contract.payment_options,
                link_rateplan: contract.link_rateplan,
                link_value: contract.link_value,
                link_type: contract.link_type

            },
        });

    }
    updateContract(item) {
        //console.log('user_info', user);

        return this.httpCall('rateplan/'+item._id,{
            method: 'PUT',
            data: {

                name: item.es.name,
                cod: item.cod,
                meal_plan: item.meal_plan,
                currency: item.currency,
                cancellation_policy: item.cancellation_policy,
                rooms: item.rooms,
                status: item.status,
                property_id:item.property_id,
                payment_options: item.payment_options,
                link_rateplan: item.link_rateplan,
                link_value: item.link_value,
                link_type: item.link_type,
                is_public: item.is_public,
            },
        });

    }
    updateContractRatetiger(item) {
        //console.log('user_info', user);

        return this.httpCall('rateplan/'+item._id,{
            method: 'PUT',
            data: {
                ratetiger_connect: item.ratetiger_connect,
                ratetiger_cod: item.ratetiger_cod,
                cancellation_policy: item.cancellation_policy,
            },
        });
    }
    updateStatus(id, data) {
        //console.log('user_info', user);

        return this.httpCall('rateplan/'+id,{
            method: 'PUT',
            data: data,
        });

    }
    deleteContract(contract_id) {

        return this.httpCall('rateplan/'+contract_id,{
            method: 'delete'
        });

    }

    getRateConfig(parameters) {
        return this.httpCall('rates-config',{
            params: parameters,
        });
    }
    getRateConfigId(rateConfig_id) {
        return this.httpCall('rate-config/'+rateConfig_id);
    }
    createRateConfig(rateConfig) {
        return this.httpCall('rate-config',{
            method: 'POST',
            data: {
                room: rateConfig.room_id,
                rateplan: rateConfig.rateplan,
                cancellation_policy: rateConfig.cancellation_policy,
                base_rate: rateConfig.base_rate,
                extra_pax: rateConfig.extra_pax
            },
        });
    }
    createRateConfigAI(dataForm) {

        //console.log('Peticion lista para enviarse a: create rate-config, con: ', dataForm)

        return this.httpCall('rate-config',{
            method: 'POST',
            data: dataForm,
        });
    }

    updateRateConfigAI(dataForm){
       //console.log('Peticion lista para enviarse a: update rate-config, con: ', dataForm)
        return this.httpCall('rate-config/'+dataForm._id,{
            method: 'PUT',
            data: dataForm,
        });
    }
    createRateConfigVinculate(rateConfig) {
       let data =  {
            room: rateConfig.room_id,
            rateplan: rateConfig.rateplan,
            cancellation_policy: rateConfig.cancellation_policy,
            base_rate: rateConfig.base_rate.value,
            room_link_type: rateConfig.base_rate.link,
            extra_pax: rateConfig.extra_pax.value,
            room_link_type_pax: rateConfig.extra_pax.link,
        }

        if(rateConfig.room_link!==''){
            data.room_link = rateConfig.room_link;
        }
        //console.log('data send', data)

        return this.httpCall('rate-config',{
            method: 'POST',
            data: data,
        });
    }








    createRateRack(rateplan_id, item, idx) {
        return this.httpCall('rateplan/'+rateplan_id+'/baserate',{
            method: 'POST',
            data: {
                name: item.name,
                value: item.value,
                order: (parseInt(idx)+1)
            },
        });
    }

    updateRateRack(rateplan_id, item, idx) {
        return this.httpCall('rateplan/'+rateplan_id+'/baserate/'+item._id,{
            method: 'PUT',
            data: {
                name: item.name,
                value: item.value,
                order: (parseInt(idx)+1)
            },
        });
    }
    deleteRateRack(rateplan_id, raterack_id) {
        return this.httpCall('rateplan/'+rateplan_id+'/baserate/'+raterack_id,{
            method: 'delete'
        });
    }
    updateRateConfigVinculate(rateConfig) {
        let data =  {
            room: rateConfig.room_id,
            rateplan: rateConfig.rateplan,
            cancellation_policy: rateConfig.cancellation_policy,
            base_rate: rateConfig.base_rate.value,
            room_link_type: rateConfig.base_rate.link,
            extra_pax: rateConfig.extra_pax.value,
            room_link_type_pax: rateConfig.extra_pax.link,
        }

        if(rateConfig.room_link!==''){
            data.room_link = rateConfig.room_link;
        }
        //console.log('data send', data)

        return this.httpCall('rate-config/'+rateConfig._id,{
            method: 'PUT',
            data: data,
        });
    }

    updateRateConfig(rateConfig) {
        return this.httpCall('rate-config/'+rateConfig._id,{
            method: 'PUT',
            data: {
                room: rateConfig.room_id,
                rateplan: rateConfig.rateplan,
                cancellation_policy: rateConfig.cancellation_policy,
                base_rate: rateConfig.base_rate,
                extra_pax: rateConfig.extra_pax
            },
        });
    }
    deletetRateConfig(rateConfig_id) {
        return this.httpCall('rate-config/'+rateConfig_id,{
            method: 'delete'
        });
    }

    desactiveRateConfig(rateConfig_id) {
        return this.httpCall('rate-config/'+rateConfig_id,{
            method: 'PUT',
            data: {
                cancellation_policy: null
            },
        });
    }


}
